
.orderdetailcard-root {
    width: 100%;
    display: inline-flex;
    margin-bottom: 15px;
}

.orderdetailcard-image-div {
    width: 100px;
    height: 100px;
}

.orderdetailcard-image {
    width: 100%;
    border: solid 1px;
    border-color: #DDDDDD;
}

.orderdetailcard-detail-div {
    margin-left: 20px;
    margin-top: 5px;
}

.orderdetailcard-iteminfo {
    font-size: 12px;
    margin-bottom: 4px;
}

.orderdetailcard-itemsoldby {
    font-size: 10px;
    margin-bottom: 4px;
}

.orderdetailcard-condition {
    font-weight: 800;
    color: #AAAAAA;
}

.orderdetailcard-price {
    color: darkred;
    font-weight: 800;
}

.orderdetailcard-orderagain {
    margin-top: 5px;
}

.orderdetailcard-fontbold {
    font-weight: 800;
}