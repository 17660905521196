
.splash-container {
    max-width: 850px;
    min-width: 850px;
    border: solid 1px;
    text-align: center;
    background-color: #ffbb11;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.splash-font-info {
    font-size: medium;
    font-weight: 800;
    font-family: Montserrat;
}

.splash-bg-image {
    /* The image used */
    background-image: url("/public/julian.jpg");

    /* Add the blur effect */
    filter: blur(8px);
    -webkit-filter: blur(8px);

    /* Full height */
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 0px;
    opacity: .5;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.splash-textpane-width {
    width: 220px;
}

.splash-margins {
    margin: auto;
}

.splash-margin-top {
    margin-top: 25px;
}

.splash-img-margins{
    margin-left: 20px;
    margin-top: 10px;
}

.splash-margin-bottom {
    margin-bottom: 20px;
}

.splash-shop-local-img {
    width: 60%;
}

.splash-ellocale-img {
    float: left;
    max-width: 115px;
    margin-bottom: 10px;
    margin-top: 20px;

}

.splash-upmargin {
    top: -20px;
    position: relative;
}

@media only screen and (max-width: 375px) {
    .splash-container {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 900px) {
    .splash-bg-image {
        display:none;
    }

    .splash-margin-norm {
        margin-left: 30px;
        margin-right: 30px;
        max-width: 450px;
    }

    .splash-sized-small {
        max-width: 450px;
    }

    .splash-small-invis {
        display: none;
    }

    .splash-small-center {
        text-align: center;
    }

    .splash-container {
        max-width: 450px;
        min-width: 450px;
        margin-bottom: 50px;
    }

    .splash-ellocale-img {
        text-align: center;
        float: bottom;
    }

    .splash-ellocale-img {
        display: none;
    }

    .splash-upmargin{
        margin-top: 50px;
    }
}