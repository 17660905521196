
.sidemenu-font {
    font-family: Montserrat, Roboto, serif;
}

.sidemenu-maxwidth {
    width: 100%;
    min-width: 120px;
}

.sidemenu-title-font {
    font-size: 16px;
    font-weight: 800;
    text-decoration: underline;
}

.sidemenu-link-font {
    font-size: 13px;
}

.sidemenu-links {
    list-style-type: none;
    padding-left: 12px;
    text-align: left;
}