
.unit-attribute-root {
    margin: 20px;
}

.unit-attribute-title {
    font-weight: 700;
    font-size: 18px;
}

.unit-description {
    font-size: 12px;
    margin-top: 10px;
}

.unit-selector {
    margin-top: 15px;
    width: 100%;
}