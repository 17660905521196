
.shippingdetails-card-root {
    border: dotted 1px #AAAAAA;
    border-radius: 10px;
}

.shippingdetails-card-button {
    text-align: center;
    transform: translateY(100%);
}

.shippingdetails-card-button-font {
    font-weight: 700;
    color: #AAAAAA;
}

.shippingdetails-darker-font {
    color: #555555;
}

.shippingdetails-card-margin-top {
    margin-top: 20px;
}

.shippingdetails-card-root-div {
    display: inline-flex;
    margin: 10px;
    height: 100%;
    vertical-align: top;
    flex-direction: column;
}

.shippingdetails-card-address {
    width: 100%;
    height: 100%;
    font-size: 12px;
}

.shippingdetails-card-default-image {
    margin-left: 15px;
    position: relative;
    top: 5px;
}

.shippingdetails-card-default-div {
    width: 100%;
    margin-bottom: 10px;
}

.shippingdetails-card-font-bold {
    font-weight: bold;
}

.shippingdetails-card-flex-full {
    margin-top: 20px;
}