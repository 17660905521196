
.elcards{
    padding: 10px;
    display: inline-flex;
}

.elcardstyle{
    width: 260px;
    height: 280px;
    textAlign: center;
    border: solid 1px;
    border-radius: 15px;
}

.pointer{
    cursor: pointer;
}