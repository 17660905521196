
div, a , input, input::placeholder {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

a {
    text-decoration: none;
}

.cart-frame {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.cart-continueshopping {
    font-size: 11px;
}

.cart-max-width {
    width: 100%;
}

.cart-aligned-center {
    text-align: center;
}

.cart-items-frame {

}

.cart-totals-frame {

}

.cart-supported-payment-div {
    width: 100%;
    text-align: center;
    margin-top: 80px;
}

.cart-supported-payment-image {
    height: 50px;
}

.cart-payment-footer {

}

.cart-display {
    display: inline-flex;
    text-align: center;
}

.cart-padtop {
    padding-top: 20px;
    width: 100%;
}

.cart-padtop-small {
    padding-top: 10px;
}

.cart-float-right {
    float: right;
}

.cart-yourcart {
    margin-top: 15px;
    font-size: 25px;
    font-weight: 700;
}

.cart-tinytext-bold {
    font-size: 10px;
    font-weight: 700;
}

.cart-font-bold {
    font-weight: 700;
}

.cart-smalltext {
    font-size: 12px;
}

.cart-padtext-top {
    padding-top: 5px;
}

.cart-padtext-bottom {
    padding-left: 7px;
    position: relative;
    top: -5px;
}

.cart-leftpane {
    width: 60%;
    max-height: 600px;
    margin-left: 50px;
    margin-right: 50px;
}

.cart-float-left {
    text-align: left;
}

.cart-margin-bottom-small {
    margin-bottom: 20px;
}

.cart-textfield-coupon {
    width: 97%;
    color: #CCCCCC!important;
    font-size: 10px!important;
}

.cart-item-div {
    max-height: 400px;
    overflow: auto;
}

.cart-button-div {
    width: 100%;
    text-align: center;
}

.cart-button {
    width: 99%;
    margin-top: 10px;
    height: 30px;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: white;
    background-color: black;
    font-size: 10px;
}

.cart-separator {
    width: 97%;
    border-top: 1px solid #AAAAAA;
    opacity: 30%;
}

.cart-notification {
    padding-top: 10px;
    font-size: 8px;
}

.cart-padleft-small {
    margin-left: 5px;
}

.cart-rightpane {
    width: 40%;
}

.cart-add-more-buttom {
    margin-top: 25px;
    width: 100%;
    background-color: white;
    color: black;
    height: 35px;
    font-size: 12px;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

@media only screen and (max-width: 850px){

    .cart-frame {
        margin:auto;
        width: 100%;
    }

    .cart-display {
        display: flow;
        margin: auto;
        text-align: center;
        width: 100%;
    }

    .cart-leftpane {
        width: 95%;
        min-width: 350px;
        padding-left: 10px;
        padding-right: 10px;
        margin: auto;
    }

    .cart-rightpane {
        width: 95%;
        min-width: 350px;
        padding-left: 10px;
        padding-right: 10px;
        margin: auto;
        padding-top: 30px;
        text-align: left;
    }

    .cart-padtext-bottom {
        position: relative;
        top: -5px;
    }
}