
.login-partner-icon{
    width: 35px;
    height: 35px;
    margin-right: 5px;
    cursor: pointer;
}

.login-icon-disabled {
    filter: grayscale(100%);
    opacity: .4;
    cursor: default;
}

.login-button-font {
    font-family: Montserrat;
    font-size: small;
    text-decoration: none;
    font-weight: bold;
    text-transform: none !important;
    color: white;
}

.loginCentered1{
    margin-left: auto;
    margin-right: auto;
}

.login-size{
    width: 350px;
    height: 425px;
}

.login-image-div{
    width: 100%;
    height: 150px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.login-pad-top {
    margin-top: 10px;
}

.login-pad-top-small {
    margin-top: 5px;
}

.login-divider-width {
    width: 70%;
}

.login-divider-margin-top-small {
    margin-top: 10px;
}

.login-aligned-center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.login-button {
    width: 100%;
}

.login-bordered {
    border: solid 1px;
    border-radius: 10px;
}

.loginMarginTop{
    margin-top: 50px;
}

.loginTextCenter{
    text-align: center;
}

.login-box-size{
    width: 80%;
}

.loginSmallSpacer{
    margin-top: 10px;
}

.loginMediumSpacer{
    margin-top: 20px;
}

.loginFontSizeSmall{
    font-size: 12px;
}

.ellocaleBackground{
    background-color: rgba(255,177,48,.4);
}

.loginButtonBorderColor{
    border-color: darkgray;
}

.loginLightFontColor{
    color: lightslategray;
}

.loginAccountCreate{
    text-decoration: underline;
    cursor: pointer;
}