
.custom-attribute-root {
    margin: 20px;
    position: relative;
}

.custom-attribute-title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    position: relative;
    top: -60px;
    z-index: 0;
}

.custom-attribute-container {
    position: relative;
    top: -30px;
}