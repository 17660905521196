
.orderup-tip-root {
    flex-grow: 1.0;
    border: solid 1px #DDDDDD;
    margin: 10px;
    min-height: 50px;
    display: grid;
    align-content: center;
    justify-content: center;
    max-width: 100px;
    border-radius: 5px;
}