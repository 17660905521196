
.accountcard-frame {

    width: 100%;
    border: solid 1px;
    border-radius: 10px;
    margin: auto;
}

.accountcard-padtop {
    margin-top: 25px;
}

.accountcard-padbottom {
    margin-bottom: 25px;
}

.accountcard-margins {
    margin-left: 25px;
    margin-right: 25px;
}

.accountcard-bold {
    font-weight: 800;
}