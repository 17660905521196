
.shippingaddress-page-root {
    width: 850px;
    margin-left: auto;
    margin-right: auto;
}

.shippingaddress-page-location {
    font-size: 12px;
    font-weight: 700;
    margin-top: 30px;
}

.shippingaddress-page-youraddresses {
    font-weight: 700;
    font-size: 24px;
    margin-top: 20px;
}

.shippingaddress-page-cards {
    margin-top: 30px;
    margin-bottom: 30px;
    /*display: flow;*/
    /*border: solid 1px;*/
    width: 100%;
}

@media only screen and (max-width: 850px)
{
    .shippingaddress-page-root {
        width: inherit;
        text-align: center;
    }
}