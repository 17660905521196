
.options-root {
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    top: -10px;
}

.options-relative-header {
    position: relative;
    width: 100%;
    font-weight: 700;
    top: -40px;
    text-align: center;
}

.options-desc {
    font-size: 12px;
}

.options-name {
    margin-top: 20px;
    display: flex;
    width: 100%;
}

.option-set-name {
    display: grid;
    align-items: center;
    flex-grow: 0.5;
    font-weight: 700;
}

.option-set-value {
    flex-grow: 20.0;
    margin-left: 20px;
}

.options-seperator {
    margin-top: 20px;
    margin-bottom: 20px;
}