
.editrow-root {
    width: 100%;
    /*border: solid 1px;*/
    display: flex;
}

.editrow-rightcolumn {
    flex-grow: .1;
    flex-direction: column;
    min-width: 100px;
    max-width: 100px;
    margin-top: 10px;
    margin-right: 15px;
}

.editrow-leftcolumn {
    flex-grow: 5.0;
    text-align: left;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 30px;
}

.editrow-button {
    width: 80px;
}

.editrow-font {
    font-size: 12px;
}

.editrow-button-font {
    /*font-family: Montserrat, Roboto, serif;*/
    /*font-size: small;*/
    font-size: 12px;
    text-decoration: none;
    font-weight: bold;
    text-transform: none !important;
    color: black;
}