.bankaccount-container {
    max-width: 500px;
    margin: auto;
}

.bankaccount-details {
    font-size: 26px;
    font-weight: 700;
}

.bankaccount-inputrow {
    display: flex;
    margin-top: 10px;
    font-weight: 700;
}