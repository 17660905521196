.launch-title-font {
    color: white;
    font-size: 55px;
}

.launch-desc-font {
    color: white;
    font-size: 20px;
}

.launch-centered {
    text-align: center;
    margin: auto;
    width: 100%;
}

.launch-bold {
    font-weight: 800;
}

.launch-join-the-community {
    text-align: center;
    width: 100%;
}

.launch-community-div {
    width: 400px;
    height: 130px;
    background-color: black;
    margin-left: auto;
    margin-right: auto;
    border-radius: 25px;
    margin-top: 10px;
}

.launch-community-img {
    height: 100px;
    margin-top: 15px;
}

.launch-shop-local {
    width: 100%;
    height: 300px;
    background-color: #ffbb11;
    text-align: center;
}

.launch-shop-local-img {
    height: 130px;
    margin-top: 20px;
}

.launch-favorite-shops {
    font-weight: 800;
    margin-top: 20px;
    font-size: 30px;
    color: #555;
}

.launch-meet-locals {
    width: 100%;
    text-align: center;
    margin-top: 40px;
    font-weight: 800;
    font-size: 30px;
}

.launch-local-businesses {
    text-align: center;
    /*display: inline-flex;*/
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
}

.launch-card-carousel {
    display: inline-flex;
}

.launch-local-business-div {
    width: 100%;
    text-align: center;
}

.launch-local-card {
    height: 380px;
    padding-left: 20px;
    padding-right: 20px;
}
