
.activateaccount-container {
    margin: 10px;
}

.activateaccount-component-container {
    max-width: 600px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}

.activateaccount-button-container {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}