
.ordersummary-root {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    width: 100%;
}

.ordersummary-rightfloat {
    float: right;
}

.ordersummary-cardcontainer {
    margin: 5px;
}

.ordersummary-bold {
    font-weight: 800;
}

.ordersummary-padtop {
    padding-top: 5px;
}

.ordersummary-fontsmall {
    font-size: 14px;
}

.ordersummary-fonttiny {
    font-size: 12px;
}

@media only screen and (max-width: 550px) {

}