
.unsub-font {
    font-family: Montserrat;
}

.unsub-fontinfo {
    font-size: 30px;
    font-weight: 700;
}

.unsub-centered {
    text-align: center;
}

.unsub-fixedwidth {
    width: 600px;
    margin-top: 30px;
}

.unsub-margins {
    margin: auto;
}

.unsub-bordered {
    border: solid 2px;
}

.unsub-margin-top {
    margin-top: 50px;
}

.unsub-margin-bottom {
    margin-bottom: 50px;
}

.unsub-redfont {
    color: red;
    font-weight: bold;
}