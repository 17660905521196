
.itemcard-card {
    border: solid 1px #CCCCCC;
    text-align: center;
    border-radius: 10px;
    margin: 10px;
}

.itemcard-align-center{
    margin-left: auto;
    margin-right: auto;
}

.itemcard-descriptions {
    text-align: left;
}

.itemcard-font {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.itemcard-bold {
    font-weight: 800;
}

.itemcard-seller{
    font-size: 12px;
    margin-top: 10px;
    margin-left: 10px;
}

.itemcard-description {
    font-size: 9px;
    margin-top: 3px;
    margin-left: 10px;
}

.itemcard-price {
    font-size: 12px;
    margin-top: 20px;
    margin-left: 10px;
}

.itemcard-hr {
    width: 95%;
    border: dot-dash 1px lightgray;
}

.itemcard-desc-text-positioining {
    position: relative;
    top: -4px;
    padding-left: 5px;
}

.itemcard-align-left {
    text-align: left;
}

.itemcard-padleft {
    padding-left: 10px;
}

.itemcard-button {
    width: 80%;
    margin-top: 15px;
    background-color: #f6aa2c;
    border-radius: 20px;
    color: black;
    height: 30px;
}

.itemcard-notextdeco {
    text-decoration: none;
}

.itemcard-favorite {
    position: relative;
    top: 14px;
    left: 2px;
    color: gray;
}