
.individual-product-root {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.individual-product-location {
    font-size: 10px;
}

.individual-product-columns {
    display: inline-flex;
    margin-top: 30px;
}

.individual-product-col1 {
    min-width: 550px;
}

.individual-product-name {
    font-size: 20px;
}

.individual-product-price {
    font-size: 12px;
}

.individual-product-button-pad {
    padding-left: 5px;
    padding-right: 5px;
}

.individual-product-quantity {
    font-size: 12px;
}

.individual-product-image-div {
    display: flow;
    width: 100%;
    text-align: center;
}

.individual-product-button {
    width: 20px;
    height: 20px;
}

.individual-product-button-div {
    margin-top: 20px;
}

.individual-product-options {
    margin-top: 20px;
}

.individual-product-option-select {
    width: 100%;
}

.individual-product-option-title {
    font-size: 10px;
    font-weight: bold;
}

.individual-product-required {
    font-size: 10px;
    color: red;
    float: right;
    opacity: 0.4;
}

.individual-product-option-font {
    font-size: 12px;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.individual-product-payment-button {
    height: 40px;
    background-color: black;
    //border-radius: 7px;
    width: 98%;
}

.individual-product-button-text {
    color: white;
}

.individual-product-desc-font{
    font-size: 8px;
}

.individual-product-image {
    width: 250px;
    height: 250px;
    position: relative;
    transform: translateY(40%)
}

.individual-product-footer {
    margin-top: 30px;
}

@media only screen and (max-width: 850px){

    .individual-product-columns {
        display: flow;
    }

    .individual-product-image {
        width: 250px;
        height: 250px;
        transform: none;
        margin-left: auto;
        margin-right: auto;
        position: inherit;
    }

    .individual-product-col1 {
        text-align: center;
        display: flex;
    }

    .individual-product-col2 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .individual-product-image-div {
        display: flow;
    }

    .individual-product-location {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 850px) {

    .individual-product-col1 {
        text-align: center;
        display: inherit;
        min-width: 350px;
    }

    .individual-product-col2 {
        margin-top: 20px;
    }
}