
.product-max-width {
    width: 100%;
}

.product-bordered {
    border: solid 1px;
}

.product-title-font {
    color: white;
    font-size: 38px;
}

.product-desc-font {
    color: white;
    font-size: 20px;
}

.product-topbar {
    /*position: relative;*/
    /*top: -20px;*/
    text-align: left;
}

.product-pad-top {
    padding-top: 20px;
}

.product-centered {
    text-align: center;
    margin: auto;
    width: 100%;
}

.product-box {
    margin-left: auto;
    margin-right: auto;
}

.product-margin-top {
    padding-top: 25px;
}

.product-margin-left {
    margin-left: 20px;
}

.product-large-pad-top {
    margin-top: 60px;
}

.product-dot {
    color: #ffbb11;
    height: 8px;
}

.product-pad-left {
    padding-left: 10px;
}

.product-results-font {
    font-size: 10px;
    position: relative;
    top: -3px;
}

.product-bold {
    font-weight: 800;
}

.product-viewport {
    margin-left: 12px;
    margin-right: 12px;
    width: 98%;
    text-align: center;
}

.product-float-left {
    float: left;
}

.product-font {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.product-select {
    position: relative;
    top: -6px;
}

.product-button-transform {
    text-transform: none;
    margin-right: 12px;
}

.product-float-right {
    float: right;
}

.product-results-text {
    font-family: Montserrat, Roboto, serif;
    font-weight: 800;
    font-size: 20px;
}

.product-center-margins {
    text-align: center;
}

.product-margin-right {
    margin-top: 30px;
}

.product-display-grid {
    display: flex;
}

.product-right-sidemenu-header {
    display: none  !important;
}

.product-left-sidemenu-header {
    display: none  !important;
}

@media only screen and (max-width: 950px) {

    .product-right-sidemenu {
        display: none;
    }

    .product-right-sidemenu-header {
        display: block  !important;
    }
}

@media only screen and (max-width: 850px) {
    .product-left-sidemenu {
        display: none;
    }

    .product-left-sidemenu-header {
        display: block  !important;
    }
}

@media only screen and (max-width: 535px) {
    .product-full-bar {
        display: none;
    }

    .product-title-font {
        font-size: 26px;
    }
}