
.category-root {
    margin: 15px 20px 15px 20px;
}

.category-title {
    font-weight: 700;
    font-size: 20px;
}

.category-explanation {
    font-size: 12px;
    margin-top: 10px;
}

.category-searchbar {
    display: flex;
    margin-top: 20px;
}

.category-search-field {
    flex-grow: 10.0;
}

.category-list-container {
    margin-top: 20px;
    max-height: 280px;
    overflow: auto;
}

.category-list-item {
    display: flex;
    /*border-top: solid 1px #DDDDDD;*/
    border-bottom: solid 1px #DDDDDD;
}

.category-list-desc {
    flex-grow: 10.0;
    display: grid;
    align-items: center;
    font-weight: 700;
    margin-left: 10px;
    font-size: 12px;
}

.category-font {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

