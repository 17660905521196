
.orderup-reward-root {
    border: solid 1px #DDDDDD;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    display: grid;
    min-height: 60px;
    height: 60px;
    align-content: center;
}

.orderup-reward-col-tiny {
    flex-grow: 1.0;
    display: grid;
    align-content: center;
}

.orderup-reward-col-large {
    flex-grow: 10.0;
}