
.ordercard-root {
    max-width: 500px;
    //border: solid 1px;
    margin-top: 15px;

}

.ordercard-arriving {
    font-weight: 800;
    font-size: 12px;
    margin-top: 10px;
    margin-left: 10px;
}

.ordercard-estimate {
    font-size: 11px;
    margin-bottom: 25px;
    margin-left: 10px;
}

.ordercard-marginleft {
    margin-left: 10px;
}

@media only screen and (max-width: 550px) {

    .ordercard-root {
        max-width: 450px;
    }

    .ordercard-arriving {

    }
}