
.loginsecurity-root {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.loginsecurity-header {
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 10px;
    margin-left: 5px;
}

.loginsecurity-editcontainer {
    border: solid 1px #DDDDDD;
    border-radius: 10px;
}

.loginsecurity-div {
    min-height: 80px;
}

.loginsecurity-topborder {
    border-top: solid 1px #DDDDDD;
}

.loginsecurity-largemargin-top {
    margin-top: 60px;
}

@media only screen and (max-width: 800px) {

    .loginsecurity-root {
        max-width: unset;
        min-width: 350px;
        margin-left: 20px;
        margin-right: 20px;
    }
}