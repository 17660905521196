
.orderup-root {
    width: 1000px;
    display: flex;
    margin: 30px auto;
}

.orderup-column {
    flex-grow: 1.0;
    max-width: 50%;
}


/*Order Up Detail Pane*/
.orderup-details-root {
    width: 100%;
    display: inline-block;
}

.orderup-details-shortflex {
    flex-grow: .1;
    min-width: 40px;
    width: 40px;
    text-align: center;
    display: grid;
    justify-items: center;
    align-items: center;
}

.orderup-details-longflex {
    flex-grow: 10.0;
    margin-left: 10px;
}

.orderup-details-container {
    width: 100%;
    display: flex;
    background-color: #EEEEEE;
}

.orderup-details-header {
    color: #BBBBBB;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
}

.orderup-details-desc {
    font-weight:bold;
    font-size: 22px;
}

.orderup-details-icon {
    background-color: lightpink;
    border: solid 1px lightpink;
    border-radius: 15px;
}

.orderup-details-pickupdetails {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 20px;
}

.orderup-details-columns {
    display: flex;

}

.orderup-details-column {
    flex: 1.0;
}

.orderup-details-alignright {
    text-align: right;
}

.orderup-details-bluebold {
    font-weight: 700;
    font-size: 12px;
    color: royalblue;
}

.orderup-details-font {
    font-weight: 700;
    font-size: 14px;
}

.orderup-pay-font {
    font-weight: 700;
    font-size: 16px;
}

.orderup-details-tricol {
    display: flex;
}

.orderup-details-tricol-tiny{
    flex-grow: .1;
    min-width: 40px;
    display: grid;
    align-content: center;
    justify-content: center;
}

.orderup-details-tricol-large {
    flex-grow: 10.0;
    margin-left: 10px;
}

.orderup-tricol-margingap {
    margin-top: 20px;
}

.orderup-details-bold-info {
    font-size: 14px;
    font-weight: 700;
}

.orderup-details-desc-small {
    font-size: 11px;
    color: #888888;
}

.orderup-details-dualcol-container {
    display: flex;
}

.orderup-details-block {
    display: flex;
}

.orderup-details-dualcol-col {
    flex-grow: 1.0;
}

.orderup-details-bordered {
    border: solid 1px;
}

.orderup-payment-details {
    width: 100%;
    text-align: center;
    height: 43px;
    min-height: 43px;
}

.orderup-payment-header {
    font-size: 20px;
    font-weight: 700;
    background-color: #EEEEEE;
    min-height: 40px;
    display: grid;
    align-content: center;
    justify-content: center;
}

.orderup-payment-smallmargin-top {
    margin-top: 10px;
}

.orderup-payment-largmargin-top {
    margin-top: 25px;
}

.orderup-pay-tip-root {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
}