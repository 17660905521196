
.item-category-dialog-root {
    /*width: 100%;*/
}

.item-category-dialog-buttonbar {
    margin: 10px;
    display: flex;
}

.item-category-left-column {
    flex-grow: 1;
}

.item-category-right-column {
    flex-grow: 3;
}

.item-category-title {
    font-size: 24px;
    font-weight: 700;
    margin: 10px;
}

.item-category-margin-top {
    margin-top: 20px;
}

.item-category-select-category-title {
    margin: 10px;
}

.item-category-searchbar {
    margin-left: 10px;
    /*width: 100%;*/
    margin-right: 10px;
    border: solid 1px;
}


/*Item Category Items*/
.item-category-item-root {
    /*width: 100%;*/
    display: flex;
    margin: 10px;
    border-bottom: dotted 1px #EEEEEE;
}

.item-category-item-small {
    flex-grow: 1;
}

.item-category-item-name {
    flex-grow: 20;
    font-weight: 700;
}

.item-category-item-float-right {
    float: right;
}

.item-category-item-center-text {
    position: relative;
    top: 10px;
}

.item-category-item-image-border {
    border: solid 1px #DDDDDD;
}