
.paymentmethod-container {
    max-width: 400px;
    margin: auto;
    padding-top: 30px;
}

.paymentmethod-title {
    font-size: 24px;
    font-weight: 700;
}

.paymentmethod-details {
    display: flex;
    width: 100%;
    margin-top: 10px;
}