
.orderconfirmation-ordernumber {
    font-size: 14px;
    font-weight: 800;
}

.orderconfirmation-ordernum-div {
    float: right;
}

.orderconfirmation-root {
    margin-right: 25px;
    margin-left: 25px;
    margin-top: 10px;
    display: block;
}

.orderconfirmation-hoticon {
    position: relative;
    top: 3px;
}

.orderconfirmation-orderplaced-span {
    font-weight: 800;
    font-size: 20px;
}

.orderconfirmation-conformation-text {
    font-size: 12px;
}

.orderconfirmation-shipping {
    margin-top: 10px;
    font-size: 14px;
}

.orderconfirmation-shippingto-span {
    font-weight: bold;
}

.orderconfirmation-bold {
    font-weight: 800;
}

.orderconfirmation-hr {
    width: 500px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.orderconfirmation-savings {
    color: darkgray;
    font-weight: 800;
    font-size: 14px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 900px) {

    .orderconfirmation-ordernum-div {
        float: none;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 550px) {

    .orderconfirmation-orderplaced-span {
        font-size: 14px;
    }

    .orderconfirmation-hr {
        width: 300px;
    }
}
