
.itemdetails-root {
    width: 100%;
    margin: 5px;
}

.itemdetails-header {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}

.itemdetails-header-small {
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    margin-left: 5px;
    margin-top: 5px;
}

.itemdetails-block {
    margin-top: 10px;
    display: flex;
}

.itemdetails-select {
    width: 100%;
}

.itemdetails-flexleft {
    /*border: solid 1px;*/
    flex-grow: 10.0;
}

.itemdetails-padtop {
    margin-top: 8px;
}

.itemdetails-labels {
    font-size: 12px;
    text-align: left;
    font-weight: 700;
}

.itemdetails-flexright {
    min-width: 180px;
    flex-grow: .3;
    margin-left: 15px;
}

.itemdetails-inputs {
    margin-top: 10px;
}

.itemdetails-form {
    width: 100%;
}

.itemdetails-imagednd {
    border: solid 1px #DDDDDD;
    height: 50px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    text-align: center;
    display: grid;
    align-items: center;
}