
.chipcontainer-root {
    margin: auto;
    text-align: center;
}

.chipcontainer-bold {
    font-weight: 700;
}

.chipcontainer-title {
    width: 50%;
    text-align: left;
    display: grid;
    align-items: center;
}

.chipcontainer-description {
    margin-top: 10px;
    font-size: 12px;
    color: #666666;
    text-align: left;
}

.chipcontainer-chips {
    margin-top: 10px;
}

.chipcontainer-titlebar {
    width: 100%;
    display: flex;
}

.chipcontainer-button {
    width: 50%;
    text-align: right;
}