.item-table-view-root {
    margin-left: 5px;
    /*border: solid 1px;*/
}

.item-table-body {

}

.item-table-bold {
    font-weight: 700 !important;
}