
.orderup-item-root {
    width: 100%;
    margin: 20px;
    }

.orderup-item-name {
    color: black;
    font-size: 13px;
}

.orderup-item-option {
    color: #999999;
}

.orderup-item-details {
    display: flex;
}

.orderup-item-details-col-large {
    flex-grow: 10.0;
}

.orderup-item-details-col-tiny {
    flex-grow: 0.1;
    min-width: 50px;
}

.orderup-item-margin-right-small {
    margin-right: 10px;
}

.orderup-item-chip-container {
    margin-top: 10px;
    margin-left: 5px;
    display: flex;
}

.orderup-item-padright {
    margin-right: 15px;
}

.orderup-details-margins {
    margin-left: 10px;
}

.orderup-details-margins-large {
    margin-left: 20px;
}

.orderup-margins-20 {
    padding: 20px 20px 0 20px;
}