
.orderconfirmationpage-root {
    width: 850px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border: solid 1px;
    border-radius: 5px;
}

.orderconfirmationpage-details {
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
}

.orderconfirmationpage-col1 {
    min-width: 525px;
}

.orderconfirmationpage-col2 {
    flex: 1.0;
    margin-top: 20px;
}

.orderconfirmationpage-ordercard {

}

@media only screen and (max-width: 950px) {

    .orderconfirmationpage-details {
        display: flow;
    }

    .orderconfirmationpage-root {
        width: 550px;
    }
}

@media only screen and (max-width: 550px) {

    .orderconfirmationpage-col1 {
        min-width: 350px;
    }

    .orderconfirmationpage-root {
        width: 375px;
        border: none;
    }

    .orderconfirmationpage-details {
        margin-left: 10px;
    }
}