
body {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

input {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

button {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.payment-aligned-center {
    text-align: center;
}

.payment-frame {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.payment-leftframe {
    width: 60%;
    /*border: solid 1px;*/
}

.payment-rightframe {
    width: 40%;
    /*border: solid 1px;*/
}

.payment-margin-medium {
    margin: 20px;
}

.payment-border-medium {
    border: solid 2px;
}

.payment-checkout {
    font-size: 16px;
}

.payment-top-up {
    position: relative;
    top: -4px;
}

.payment-margin-small {
    margin: 5px;
}

.payment-bold {
    font-weight: bold;
}

.payment-medium-font {
    font-size: 12px;
}

.payment-small-font {
    font-size: 10px;
}

.payment-margin-top-large {
    margin-top: 35px;
}

.payment-gray-font {
    color: darkgray;
}

.payment-margin-top-medium {
    margin-top: 20px;
}

.payment-margin-top-small {
    margin-top: 10px;
}

.payment-margin-top-tiny {
    margin-top: 5px;
}

.payment-button {
    width: 230px;
    height: 40px;
    background-color: black;
    border-radius: 7px;
}

.payment-payment-button {
    height: 40px;
    background-color: black;
    border-radius: 7px;
    width: 98%;
}

.payment-applepay {
    height: 30px;
    margin-top: 5px;
}

.payment-margin-left {
    margin-left: 15px;
}

.payment-textfield-coupon {
    width: 97%;
    color: #CCCCCC!important;
    font-size: 10px!important;
}

.payment-textfield-std {
    color: #CCCCCC!important;
    font-size: 10px!important;
}

.payment-margin-left-tiny {
    margin-left: 5px;
}

.payment-margin-left-large {
    margin-left: 45px;
}

.payment-padding-left {
    padding-left: 20px;
}

.payment-button-text {
    color: white;
}

.payment-hr {
    border-top: solid 1px;
    border-top-color: lightgray;
    width: 98%;
}

.payment-div {
    display: flex;
    max-height: 40px;
}

.payment-flex-2 {
    flex-grow: 2
}

.payment-max-width-small {
    max-width: 100px;
}

.payment-flex-1 {
    flex-grow: 0.5;
}

.payment-fullsize {
    width: 100%;
}

.payment-max-width {
    width: 100%;
}

.payment-phone-prefix {
    width: 200px;
}

.payment-margin-bottom {
    position: relative;
    top: -3px;
}

.payment-flex {
    flex-grow: 2.0;
}

.payment-control-height {
    height: 60px;
}

.payment-span-right {
    padding-right: 10px;
}

.payment-float-right {
    float: right;
}

.payment-pad-bottom-medium {
    margin-bottom: 20px;
}

@media only screen and (max-width: 850px){
    .payment-frame {
        display: flow;
        text-align: center;
        max-width: 480px;
        min-width: 480px;
    }

    .payment-leftframe {
        display: flow;
        text-align: center;
        width: 100%;
    }

    .payment-rightframe {
        width: 100%;
        margin-bottom: 30px;
    }

    .payment-margin-left-large {
        margin-left: 0;
    }

    .payment-margin-left-large {
        text-align: left;
    }
}

@media only screen and (max-width: 499px){
    .payment-frame {
        min-width: 350px;
        max-width: 350px;
    }

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .MuiOutlinedInput-root {
        width: 100%;
    }

    .payment-button {
        width: 100px;
    }

    .payment-frame-responsive {
        display: inline-block;
    }

    .payment-left-frame {
        width: 100%;
        min-width: 300px;
        text-align: left;
    }

    .payment-frame {
        text-align: left !important;
    }

    .payment-div {
        display: inline-block;
        max-height: 800px;
        width: 100%;
    }

    .payment-span-right {
        padding-right: 0;
        padding-top: 10px;
        max-height: 400px;
    }

    .payment-flex {
        width: 100%;
        flex-grow: 0.0;
    }

    .payment-margin-left-large {
        text-align: left;
    }
}