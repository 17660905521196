
.shopcard-width {
    width: 260px;
    min-width: 250px;
    border: dotted 1px #CCCCCC;
}

.shopcard-max-width {
    max-height: 120px;
    max-width: 260px;
    margin: auto;
}

.shopcard-min-height {
    min-height: 160px;
}

.shopcard-font {
    font-family: Montserrat, Roboto, serif;
}

.shopcard-text-div {
    margin-top: 0px;
}

.shopcard-text-block-gift {
    font-size: 10px;
    text-align: left;
}

.shopcard-padbottom {
    padding-bottom: 10px;
}

.shopcard-text-block {
    text-align: left;
    width: 100%;
    padding-left: 5px;
}

.shopcard-text-block-vendor {
    font-size: 18px;
    font-weight: 800;
    min-height: 50px;
}

.shopcard-text-block-description {
    font-size: 11px;
    color: #555555;
    padding-top: 5px;
    height: 50px;
}

.shopcard-padtop {
    padding-top: 20px;
}

.shopcard-text-block-address {
    font-size: 13px;
    font-weight: 800;
}

.shopcard-padleft {
    padding-left: 10px;
}

.shopcard-padleft-small {
    padding-left: 5px;
}

.shopcard-desc-text-positioining {
    position: relative;
    top: -4px;
    padding-left: 5px;
}


.shopcard-centered {
    text-align: center;
}

.shopcard-button {
    margin-top: 8px;
    text-align: center;
    width: 90%;
    height: 30px;
    border: solid 0;
    font-weight: bold;
    background-color: #ffbb11;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
}