
.identify-container {

}

.identity-title {
    font-size: 26px;
    text-align: left;
}

.identity-bold {
    font-weight: 700;
}

.identity-description {
    text-align: left;
    margin-top: 10px;
}

.identity-form-container {
    margin-top: 40px;
}

.identity-form-item {
    width: 100%;
}

.identity-form-div {
    margin-top: 20px;
}

.identity-form-item-constrained {
    width: 90%;
}