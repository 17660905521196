
.interest-container {

    text-align: center;
    border-radius: 5px;
    background-color: white;
    margin-right: 20px;
    margin-left: 20px;
}

.interest-bg-color {
    background-color: #ffbb11;
}

.interest-bg-color-white {
    background-color: white;
}

.interest-font-complete {
    font-size: 30px;
    font-weight: 700;
}

.interest-font-local {
    font-size: 20px;
    font-weight: 700;
}

.interest-font {
    font-family: Montserrat;
    font-size: smaller;
}

.interest-font-base {
    font-family: Montserrat;
}

.interest-img-margins {
    text-align: center;
    max-width: 100px;
    margin-top: 30px;
}

.interest-success-margin {
    padding-top: 150px;
}

.interest-button-font {
    font-family: Montserrat;
    font-size: small;
    text-decoration: none;
    font-weight: bold;
    text-transform: none !important;
    color: black;
}

.interest-complete-minheight {
    min-height: 600px;
}

.interest-margin-top {
    margin-top: 20px;
}

.interest-margin-top-small {
    margin-top: 10px;
}

.interest-margin-bottom {
    margin-bottom: 20px;
}

.interest-padding-bottom {
    padding-bottom: 20px;
}

.interest-override-height {
    height: 270px;
}

.interest-logo-size {
    width: 40%;
}

.interest-logo-padding {
    margin-top: 10px;
}

.interest-padbottom {
    padding-bottom: 25px;
}

.interest-font-large {
    font-size: x-large;
    font-weight: 800;
}

.interest-font-l {
    font-size: large;
    font-weight: 800;
}

.interest-not-shown {
    display: none;
}

.interest-shown {
    display: flex;
}

.interest-padtop {
    padding-top: 25px;
}

.interest-underlined {
    text-decoration: underline;
}

.interest-bold {
    font-weight: 800;
}

.interest-padsides {
    padding-left: 20px;
    padding-right: 20px;
}

.interest-font-small {
    font-family: Montserrat;
    font-size: small;
    font-weight: 700;
}

.interest-font-tiny {
    font-size: x-small;
}


.interest-width-large {
    width: 70%;
}

.interest-side-margins {
    margin-left: auto;
    margin-right: auto;
}

.interest-max-width {
    width: 100%;
}

.interest-text-size {
    font-size: 10px;
}

.interest-input-height {
    height: 30px;
}

.interest-button-weight {
    font-weight: 400;
}

.interest-aligned-center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.interest-button-width {
    width: 100px;
}

.interest-pad-right {
    margin-right: 5px;
}

.interest-small-margins {
    margin-left: 15px;
    margin-right: 15px;
}

.interest-light-font-color {
    color: lightslategray;
}

.interest-fake-link {
    color: blue;
}

.interest-complete-bar {

}