
.header-full {
    width: 99.8%;
    height: 40px;
    background-color: black;
    border: solid 1px;
    color: #ffbb11;
    text-align: center;
}

.header-icon {
    width: 36px;
    height: 36px;
    margin-left: 30px;
}

.header-elloc {
    width: 100px;
    position: relative;
    top: 10px;
    margin-right: 20px;
}

.search {
    height: 26px;
    position: relative;
    top: 3px;
    border-radius: 5px;
    width: 300px;
}

.header-centered {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
}

.header-roundedcorners {
    border-radius: 20px;
}

.header-farright {
    /*position: relative;*/
    /*left: 30px;*/
}

.header-float-right {
    float: right;
}

.header-elloc-image {
    width: 90px;
    position: relative;
    top: -3px;
}

.header-cart-img {
    padding-left: 30px;
    margin-top: 8px;
    color: white;
}

.header-small-font {
    font-size: 10px;
    margin-top: 3px;
}

.header-bold {
    font-weight: 800;
}

.header-other {
    margin-left: 30px;
    position: relative;
}

.header-minus-nine {
    top: -9px;
}

.header-minus-eight {
    top: -8px;
}

.header-margin-top {
    padding-top: 10px;
}

.header-float-left {
    float: left;
}

.header-padright {
    margin-right: 20px;
}

.header-padleft {
    margin-left: 20px;
}

.header-shortenpadding {
    padding-top: 1px;
}

/*
RESPONSIVE
 */

.header-responsive {
    display: none;
}

.header-responsive-textfield {
    color: white;
}

@media only screen and (max-width: 950px) {
    .header-full {
        display: none;
    }

    .header-responsive {
        display: block;
        width: 100%;
    }
}