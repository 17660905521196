
.aboutus-root {
    width: 1000px;
    margin: auto;
}

.aboutus-column {
    margin: 20px;
    font-weight: 800;
}

.aboutus-header {
    font-size: 40px;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 20px;
}

.aboutus-display {
    display: flex;
    margin-bottom: 30px;
}

.aboutus-image {
    width: 500px;
}

.aboutus-responsive {
    display: none;
}

@media only screen and (max-width: 1000px) {

    .aboutus-image {
        width: 350px;
        margin: auto;
    }

    .aboutus-root {
        width: 850px;
    }

    .aboutus-column {
        font-size: 13px;
        margin: 30px;
    }

    .aboutus-image-div {
        display: grid;
    }
}

@media only screen and (max-width: 825px) {

    .aboutus-root {
        display: none;
    }

    .aboutus-responsive {
        display: grid;
    }

    .aboutus-header {
        text-align: center;
    }

    .aboutus-image {
        width: unset;
        max-width: 75%;
        margin-bottom: 20px;
    }

    .aboutus-column {
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
    }
}