
.mission-layout {
    text-align: center;
    margin: auto;
    background-color: white;
    font-family: Montserrat;
    border-radius: 5px;
    font-weight: 800;
    width: 60%;
}

.mission-transform {
    transform: translate(0px, 50px);
}

.mission-content-container {
    margin-left: 20px;
    margin-right: 20px;
}

@media only screen and (max-width: 650px) {
    .mission-transform {
        transform: translate(0px, 20px);
    }
}

@media only screen and (max-width: 500px) {
    .mission-transform {
        transform: translate(0px, 5px);
    }
}

@media only screen and (max-width: 349px) {
    .mission-transform {
        transform: translate(0px, 10px);
        font-size: 12px;
    }
}

@media only screen and (max-width: 250px) {
    .mission-transform {
        transform: translate(0px, 10px);
        font-size: 10px;
    }
}