
.businesscard-root {
    width: 98%;
    border: solid 1px #DDDDDD;
    margin: 10px;
    border-radius: 20px;
}

.businesscard-inline {
    display: flex;
}

.businesscard-details {
    flex-grow: 2.0;
}

.businesscard-vendor {
    margin-top: 15px;
    font-weight: 700;
}

.businesscard-vendor-details {
    margin-top: 10px;
}

.businesscard-buttondiv {
    margin: auto;
    min-width: 140px;
}

.businesscard-button {
    border-radius: 20px;
    background-color: #ffbb11;
    font-weight: 700;
    min-width: 120px;
    min-height: 35px;
}

.busincesscard-image {
    margin-left: 15px;
    margin-right: 15px;
}

@media only screen and (max-width: 600px) {
    .businesscard-vendor {
        font-size: 12px !important;
    }

    .businesscard-vendor-details {
        font-size: 10px !important;
    }
}

@media only screen and (max-width: 400px) {
    .businesscard-button {
        min-width: 100px;
    }
}