
.age-verification-root {
    margin: 15px 20px 15px 20px;
}

.age-verification-title {
    font-weight: 700;
    font-size: 20px;
}

.age-verification-explanation {
    font-size: 12px;
    margin-top: 10px;
}

.age-verification-location-title {
    margin-top: 25px;
    font-weight: 700;
    font-size: 16px;
}

.age-verification-age-restriction-div {
    width: 100%;
    border: solid 1px;
    display: flex;
    margin-top: 15px;
}

.age-verification-age-restriction-selector {
    flex-grow: 10.0;
}

.age-verification-description {
    font-size: 12px;
    margin-top: 20px;
}

.age-verification-font {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

