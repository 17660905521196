.footer {
    width: 100%;
    background-color: lightgray;
    color: #FFFFFF;
    /*margin-top: auto;*/
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    border-top: solid 1px black;
    padding-top: 10px;
    text-align: center;
}

.header-icon {
    width: 36px;
    height: 36px;
    margin-left: 30px;
}

.header-elloc {
    width: 100px;
    position: relative;
    top: 10px;
    margin-right: 20px;
}

.footer-links {
    font-size: 12px;
}

.footer-block {
    text-align: center;
    margin-top: 10px;
}

.footer-padtop {
    padding-top: 5px;
}

.footer-pad-bottom {
    padding-bottom: 10px;
}

.footer-stickybottom {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.footer-title {
    color: black;
    font-weight: 700;
}

.footer-pad-right {
    margin-right: 8px;
}

.footer-icon-link {
    cursor: pointer;
}

.footer-hidden {
    display: none;
    visibility: hidden;
}