
.giftcard-root {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    min-width: 370px;
    /*border: solid 1px;*/
}

.giftcard-header {
    font-size: 24px;
    font-weight: 800;
}

.giftcard-claimbox {
    margin-top: 30px;
    margin-left : auto;
    margin-right: auto;
    /*max-width: 800px;*/
}

.giftcard-claimfont {
    font-size: 10px;
    font-weight: 700;
}

.giftcard-textandbutton {
    display: flex;
}

.giftcard-textandbutton-left {
    flex-grow: 6.0;
    margin-right: 10px;
}

.giftcard-textandbutton-right {
    margin-left: 10px;
    flex-grow: 4.0;
    min-width: 200px;
    max-width: 200px;
}

.giftcard-field {
    width: 100%;
}

.giftcard-balance {
    margin-top: 35px;
    font-weight: 700;
    color: #777777
}

.giftcard-link {
    font-size: 10px;
    font-weight: 700;
}

.giftcard-terms {
    margin-left: 30px;
    margin-right: 30px;
    font-weight: 700;
    font-size: 12px;
    font-style: italic;
}

.giftcard-help {
    font-size: 10px;
}

@media only screen and (max-width: 800px) {
    .giftcard-root {
        margin-left: 30px;
        margin-right: 30px;
    }
}

@media only screen and (max-width: 600px) {

    .giftcard-textandbutton {
        display: flow;
    }

    .giftcard-textandbutton-right {
        margin: auto;
        padding-top: 20px;
    }
}