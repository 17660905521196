
.ownership-container {
    width: 100%;
    margin: 10px;
}

.ownership-question {
    font-size: 26px;
}

.ownership-bold {
    font-weight: 700;
}

.ownership-description {
    font-size: 12px;
    margin-top: 20px;
    text-align: left;
}

.ownership-combo {

}

.ownership-role-container {
    margin-top: 40px;
}

.ownership-additional-beneficials {
    margin-top: 30px;
    text-align: left;
    margin-left: 10px;
}

.ownership-beneficiaries {
    margin-top: 30px;
    font-size: 12px;
}