
.contactus-root {
    width: 1000px;
    margin: auto;
}

.contactus-column {
    margin: 20px;
    font-weight: 800;
    /*max-width: 50%;*/
    min-width: 50%;
}

.contactus-header {
    font-size: 40px;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 20px;
}

.contactus-display {
    display: flex;
    margin-bottom: 30px;
}

.contactus-image {
    width: 500px;
}

.contactus-responsive {
    display: none;
}

.contactus-image-div {
    font-size: 12px;
    margin-right: 80px;
    margin-top: 100px;
    text-align: center;
}

.contactus-textfield-div-small {
    margin: 15px;
}

.contactus-textfield-small {
    width: 100%;
}

.contactus-disclaimer {
    margin-left: 40px;
    margin-right: 40px;
    text-align: center;
    font-weight: normal;
    font-size: 12px;
}

.contactus-button {
    margin-top: 20px;
    text-align: right;
    margin-right: 15px;

}

@media only screen and (max-width: 1000px) {

    .contactus-root {
        width: unset;
    }

    .contactus-header {
        margin-left: 20px;
    }

    .contactus-image-div {
        display: none;
    }

    .contactus-column {
        width: 100%;
        margin-right: 20px;
    }
}