
.vendoraccount-row {
    display: inline-grid;
    grid-template-columns: 50% 50%;
    margin-top: 10px;
    width: 100%;
}

.vendoraccount-item-right {
    margin-left: 10px;
}

.vendoraccount-icon {
    position: relative;
    top: 3px;
    margin-right: 10px;
}

.vendoraccount-item {

}

.vendoraccount-root {
    max-width: 800px;
    margin: auto;
    margin-bottom: 35px;
    /*border: solid 1px;*/
}

.vendoraccount-title {
    font-size: 24px;
    font-weight: 800;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 20px;
}

@media only screen and (max-width: 850px){

    .vendoraccount-row {
        display: flow;
        width: 95%;
        margin: auto;
        margin-bottom: 20px;
    }
}