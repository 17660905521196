.item-table-menu-root {
    min-width: 200px;
    max-width: 200px;
    padding-left: 20px;
}

.item-table-menu-selector {
    font-weight: 700;
    /*margin-top: 20px;*/
    margin-left: 10px;
}

.item-table-menu-menu-item {
    margin-top: 10px;
}

.item-table-menu-paper {
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}