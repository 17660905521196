
.paymentinfo-root {
    width: 850px;
    margin: auto;
}

.paymentinfo-title {
    font-size: 24px;
    font-weight: 700;
    margin-top: 25px;
}

.paymentinfo-contactcard {
    margin: 20px;
    border-radius: 10px;
    border: solid 1px #DDDDDD;
    border-bottom: solid 3px #CCCCCC;
}

.paymentinfo-container {
    margin-left: 25px;
    margin-top: 25px;
}

.paymentinfo-contactinfo {
    font-size: 14px;
    font-weight: 700;
}

.paymentinfo-userinfo {
    font-weight: normal;
    font-size: 12px;
    width: 100%;
    color: #777777;
    display: inline-flex;
}

.paymentinfo-arrowcontainer {
    margin-right: 30px;
}

.paymentinfo-savedpayments {
    font-weight: 700;
    font-size: 14px;
}

.paymentinfo-cardinfo {
    font-size: 12px;
    color: #777777;
    margin-top: 10px;
    margin-left: 5px;
}

.paymentinfo-spans {
    margin-left: 10px;
    position: relative;
    top: -8px;
}

.paymentinfo-paymentmethodadd {
    font-size: 12px;
    color: #777777;
    margin-top: 10px;
    font-weight: 700;
    margin-left: 5px;
}