
.variation-relative-header {
    position: relative;
    width: 100%;
    font-weight: 700;
    top: -40px;
    text-align: center;
    font-size: 18px;
}

.variation-separator {
    margin-top: 15px;
    margin-bottom: 20px
}

.variation-root {
    position: relative;
    top: -30px;
    margin-left: 20px;
    margin-right: 20px;
    z-index: 0;
}

.variation-label {
    font-weight: 700;
    flex-grow: 1.0;
    min-width: 150px;
    max-width: 150px;
    background-color: #EEEEEE;
    height: 40px;
    font-size: 12px;
    display: grid;
    align-items: center;
    padding-left: 10px;
}

.variation-value {
    width: 100%;
}

.variation-sales-info {
    font-size: 19px;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 10px;
}

.variation-dragdrop {
    height: 55px;
    display: grid;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    border: solid 1px #EEEEEE;
}

.variation-photo-image {
    position: relative;
    top: 7px;
    margin-right: 10px;
}