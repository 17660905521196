
.addeditaddress-root {
    width: 850px;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    margin: auto;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.addeditaddress-columnitem {
    flex-grow: 1;
    margin: 5px;
}

.addeditaddress-columncontainer {
    display: flex;
    width: 100%;
}

.addeditaddress-columncontainer-right {
    width: 100%;
    text-align: right;
}

.addeditaddress-extra-margin-top {
    margin-top: 10px;
}

.addeditaddress-title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 30px;
}

.addeditaddress-marginleft {
    margin-left: 10px;
}

.addeditaddress-extra-margin-righttop {
    margin-right: 10px;
    margin-top: 20px;
}

.addeditaddress-hidden {
    display: none;
}

@media only screen and (max-width: 880px) {
    .addeditaddress-root {
        width: inherit;
        margin-left: 20px;
        margin-right: 20px;
    }
}