.ordercard-fulfillment-root {

    width: 100%;
    border: solid 1px #666666;
    border-radius: 10px;
    margin-top: 20px;
}

.ordercard-fulfillment-columns {
    display: flex;
    padding-bottom: 10px;
    padding-left: 20px;
    font-size: 12px;
    background-color: #DDDDDD;
    border-radius: 10px;
    border-bottom: solid 3px #AAAAAA;
}

.ordercard-fulfillment-status-item-details {
    display: flex;
    margin-bottom: 10px;
}

.ordercard-fulfillment-tiny-column {
    flex-grow: .3;
    max-width: 120px;
}

.ordercard-fulfillment-image {
    border: solid 1px #DDDDDD;
}

a{
    color: mediumblue !important;
}

.ordercard-fulfillment-margin-top-small {
    margin-top: 6px;
}

.ordercard-fulfillment-large-column {
    flex-grow: 1.0;
    font-weight: 700;
    font-size: 13px;
}


.ordercard-fulfillment-column {
    flex-grow: 1.0;
    margin-top: 10px;
}

.ordercard-fulfillment-column-header {
    font-weight: 700;
}

.ordercard-fulfillment-margin-left-small {
    margin-left: 15px;
}

.ordercard-fulfillment-bluecolor {
    color: blue;
}

.ordercard-fulfillment-status {
    font-size: 20px;
    font-weight: bold;
}

.ordercard-fulfillment-status-div {
    margin-top: 10px;
    margin-left: 20px;
}

.ordercard-fulfillment-status-update {
    font-size: 14px;
}

@media only screen and (max-width: 500px) {

    .ordercard-fulfillment-responsive-optional {
        display: none;
    }
}