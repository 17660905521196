.managestock-header {
    font-weight: 700;
    border-bottom: solid 2px #DDDDDD;
    font-size: 12px;
    border-right: solid 1px #DDDDDD;
}

.managestock-title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    position: relative;
    top: -40px;
}

.managestock-column-standard {
    font-size: 12px;
    height: 40px;
    border-bottom: solid 1px #DDDDDD;
    border-right: solid 1px #DDDDDD;
}

.managestock-font {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
}

.managestock-root {
    position: relative;
    top: -20px;
    margin: 20px;
    height: 400px;
}

.managestock-col-large {
    min-width: 245px;
    display: grid;
    align-items: center;
    padding-left: 10px;
}

.managestock-col-small {
    min-width: 100px;
    max-width: 100px;
    text-align: center;
    display: grid;
    align-items: center;
    /*border: solid 1px;*/
}

.managestock-col-medium {
    min-width: 190px;
    max-width: 190px;
    text-align: center;
    display: grid;
    align-items: center;
}

.managestock-col-tiny {
    min-width: 70px;
    max-width: 70px;
    text-align: center;
    display: grid;
    align-items: center;
}

.managestock-column-1 {
    background-color: #EEEEEE;
    border-right-color: #AAAAAA;
}