
.quickaccess-container {
    max-width: 400px;
    width: 100%;
    margin: 10px;
}

.quickaccess-title {
    display: flex;
}

.quickaccess-bold {
    font-weight: 700;
}

.quickaccess-link {
    color: royalblue;
}

.quickaccess-titledesc {
    flex-grow: 10.0;
}

.quickaccess-edit {
    flex-grow: .5;
}

.quickaccess-option {
    display: flex;
}

.quickaccess-icon {
    transform: scale(0.5);
}

.quickaccess-item-container {
    margin-top: 10px;
}

.quickaccess-item {
    display: grid;
    margin-left: 10px;
    margin-top: 10px;
    align-content: center;
}

.quickaccess-lionshare {
    flex-grow: 20.0;
}