
.sellable-div {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
}

.sellable-image {
    max-width: 80px;
}

.sellable-float-left {
    float: left;
}

.sellable-description-pane {
    margin-left: 5px;
}

.sellable-margin-bottom {
    margin-bottom: 22px;
}

.sellable-margin-large {
    margin-top: 35px;
}

.sellable-align-right {
    flex-grow: 3;
    text-align: right;
}

.sellable-button {
    width: 20px;
    height: 20px;
}

.sellable-pad-top {
    margin-top: 10px;
}

.sellable-button-pad {
    padding-left: 5px;
    padding-right: 5px;
}

.sellable-button-div {
    margin-top: 10px;
}

.sellable-img-min-width {
    min-width: 80px;
}

.sellable-small-font {
    font-size: 10px;
}

.sellable-font-bold {
    font-weight: 700;
}