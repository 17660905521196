
.order-deliver-card-root {
    width: 850px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.order-delivery-card-yourorders {
    font-weight: 800;
    font-size: 24px;
    position: relative;
    top: 4px;
}

.order-delivery-card-right {
    float: right;
}

.order-delivery-text-field-span {
    margin-right: 10px;
}

.order-delivery-card-button {
    width: 100px;
}

.order-delivery-card-searchbox {

}

.order-delivery-menu-bar {
    margin-top: 30px;
}

.order-delivery-card-bold {
    font-weight: 700;
}

.order-deliver-margin-left {
    margin-left: 40px;
}

.order-delivery-ordersplaced {
    font-size: 12px;
}

.order-deliver-days {
    margin-left: 15px;
    width: 140px;
    padding-left: 5px;
    padding-right: 5px;
    border: solid #DDDDDD 1px;
    border-radius: 5px;
    font-weight: normal;
}

@media only screen and (max-width: 850px) {

    .order-deliver-card-root {
        min-width: 300px;
        width: inherit;
        margin-left: 25px;
        margin-right: 25px;
    }
}

@media only screen and (max-width: 600px) {

    .order-deliver-card-root {
        margin-left: 25px;
        margin-right: 25px;
    }

    .order-deliver-responsive-optional {
        display: none;
    }
}

@media only screen and (max-width: 390px) {

    .order-deliver-card-root {
        margin-left: 15px;
        margin-right: 15px;
        max-width: 380px;
    }

    .order-deliver-responsive-optional {
        display: none;
    }
}