
.business-toolbar {
    width: 100%;
    display: flex;
    margin-top: 10px;
    border-bottom: solid 2px #BBBBBB;
}

.business-toolbar-navigation {
    flex-grow: .1;
    display: flex;
}

.business-toolbar-title {
    flex-grow: 20.0;
    text-align: center;
}

.business-toolbar-options {
    flex-grow: .1;
    display: flex;
}

.business-toolbar-menuicon {
    margin-right: 10px;
    margin-left: 10px;
}

.business-toolbar-nav-grid {
    display: grid;
    align-content: center;
}

.business-toolbar-image-pad {
    margin-left: 15px;
}

.business-toolbar-business {
    text-align: right;
    margin-right: 10px;
}

.business-toolbar-bold {
    font-weight: 700;
}