
.stocktracking-title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    position: relative;
    top: -40px;
}

.stocktracking-root {
    position: relative;
    top: -20px;
    margin: 20px;
    height: 400px;
}

.stocktracking-header {
    font-weight: 700;
    border-bottom: solid 2px #DDDDDD;
    font-size: 12px;
    border-right: solid 1px #DDDDDD;
}

.stocktracking-header-left{

}

.stocktracking-header-center {
    text-align: center;
}

.stocktracking-column-standard {
    font-size: 12px;
    height: 40px;
    border-bottom: solid 1px #DDDDDD;
    border-right: solid 1px #DDDDDD;
}

.stocktracking-column-1 {
    background-color: #EEEEEE;
    border-right-color: #AAAAAA;
}

.stocktracking-warning {
    border: solid 1px yellow;
    border-radius: 10px;
    padding: 2px;
    background-color: yellow;
}

.stocktracking-col-large {
    min-width: 245px;
    display: grid;
    align-items: center;
    padding-left: 10px;
}

.stocktracking-col-small {
    min-width: 100px;
    max-width: 100px;
    text-align: center;
    display: grid;
    align-items: center;
    /*border: solid 1px;*/
}